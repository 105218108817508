import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";

export const GreenButton = styled.a`
  display: block;
  width: fit-content;

  background: ${theme.colors.green};
  border-radius: 40px;
  padding: 16px 30px;

  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
  color: ${theme.colors.white} !important;
  cursor: pointer;
  transition: all 0.1s linear;

  :hover,
  :focus {
    text-decoration: none;
    color: ${theme.colors.white};
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), #00b183;
  }

  :active {
    text-decoration: none;
    color: ${theme.colors.white};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #00b183;
  }

  &:disabled {
    cursor: default;
    background: #5e87c1;
    color: #cbcbcb;
    opacity: 0.8;
  }

  @media (max-width: 400px) {
    padding: 16px 24px;
    width: 100%;
    &.first {
      font-size: 18px;
    }
    &.second {
      font-size: 14px;
    }
  }
`;
