import React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { Title } from "../ui/title";
import { MouseScrollContainer } from "./mouseScrollContainer/mouseScrollContainer";
import image1 from "../shared/img/photo_2022/image1.jpg";
import image2 from "../shared/img/photo_2022/image2.jpg";
import image3 from "../shared/img/photo_2022/image3.jpg";
import image4 from "../shared/img/photo_2022/image4.jpg";
import image5 from "../shared/img/photo_2022/image5.jpg";
import image6 from "../shared/img/photo_2022/image6.jpg";
import image7 from "../shared/img/photo_2022/image7.jpg";
import image8 from "../shared/img/photo_2022/image8.jpg";
import image9 from "../shared/img/photo_2022/image9.jpg";
import image10 from "../shared/img/photo_2022/image10.jpg";
import image11 from "../shared/img/photo_2022/image11.jpg";
import image12 from "../shared/img/photo_2022/image12.jpg";

export const SectionMosaic: React.FC = () => {
  return (
    <Container>
      <CustomTitle className="container">
        Мы ждем всех, кто бежит в ногу со временем!
      </CustomTitle>
      <MouseScrollContainer>
        <ActivityContainer>
          <ImageBlock>
            <BigImg backgroundImg={image8} />
            <DownImageContainer>
              <FirstSmallImage>
                <SmallImg backgroundImg={image2} />
              </FirstSmallImage>
              <SmallImg backgroundImg={image3} />
            </DownImageContainer>
          </ImageBlock>

          <ImageBlock>
            <TopImageContainer>
              <FirstSmallImage>
                <SmallImg backgroundImg={image4} />
              </FirstSmallImage>
              <SmallImg backgroundImg={image5} />
            </TopImageContainer>
            <BigImg backgroundImg={image6} />
          </ImageBlock>

          <ImageBlock>
            <BigImg backgroundImg={image7} />
            <DownImageContainer>
              <FirstSmallImage>
                <SmallImg backgroundImg={image12} />
              </FirstSmallImage>
              <SmallImg backgroundImg={image9} />
            </DownImageContainer>
          </ImageBlock>

          <ImageBlock>
            <TopImageContainer>
              <FirstSmallImage>
                <SmallImg backgroundImg={image10} />
              </FirstSmallImage>
              <SmallImg backgroundImg={image11} />
            </TopImageContainer>
            <BigImg backgroundImg={image1} />
          </ImageBlock>
        </ActivityContainer>
      </MouseScrollContainer>

      <div id={"reg"} />
    </Container>
  );
};

const Container = styled.section`
  background: ${theme.colors.white};
`;

const CustomTitle = styled(Title)`
  margin-bottom: 68px;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 34px;
  }
`;

const ActivityContainer = styled.div`
  margin: auto;
  display: flex;
  max-height: 681px;
  user-select: none;

  @media screen and (min-width: 1920px) {
    justify-content: center;
  }
`;

const ImageBlock = styled.div`
  margin-right: 9px;
  height: auto;
  pointer-events: none;

  @media screen and (max-width: 768px) {
    margin-right: 7px;
  }
`;

const DownImageContainer = styled.div`
  padding-top: 9px;
  display: flex;

  @media screen and (max-width: 768px) {
    padding-top: 7px;
  }
`;

const TopImageContainer = styled.div`
  padding-bottom: 9px;
  display: flex;

  @media screen and (max-width: 768px) {
    padding-bottom: 7px;
    display: flex;
  }
`;

const FirstSmallImage = styled.div`
  padding-right: 9px;
`;

const BigImg = styled.div<{ backgroundImg; backgroundPositionX?: string }>`
  background-image: url(${(props) => props.backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;

  background-position-x: ${(props) =>
    props.backgroundPositionX ? props.backgroundPositionX : "center"};

  width: 469px;
  height: 366px;

  @media screen and (max-width: 768px) {
    width: 357px;
    height: 279px;
  }
`;

const SmallImg = styled.div<{ backgroundImg; backgroundPositionX?: string }>`
  background-image: url(${(props) => props.backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;

  background-position-x: ${(props) =>
    props.backgroundPositionX ? props.backgroundPositionX : "center"};

  width: 230px;
  height: 226px;
  @media screen and (max-width: 768px) {
    width: 173px;
    height: 175px;
  }
`;
