import React from "react";
import fb from "../../shared/icons/fb.svg";
import linkedin from "../../shared/icons/in.svg";
import telegram from "../../shared/icons/telegram.svg";
import instagram from "../../shared/icons/insta.svg";
import vk from "../../shared/icons/vk.svg";
import { styled } from "../../core/styledComponents";

export class SocialNetwork extends React.Component {
  public render() {
    return (
      <SocialNetworkContainer className={"col-lg-3 col-md-2"}>
        <IconContainer href={"http://www.facebook.com/dextechnology"} target={"_blank"} aria-label={"facebook"}>
          <Icon alt={""} src={fb} />
        </IconContainer>
        <IconContainer href={"https://t.me/itpmr"} target={"_blank"} aria-label={"telegram"}>
          <Icon alt={""} src={telegram} />
        </IconContainer>
        <IconContainer
          href={"http://www.linkedin.com/company/dextechnology"}
          target={"_blank"}
          aria-label={"instagramm"}
        >
          <Icon alt={""} src={linkedin} />
        </IconContainer>
        <IconContainer href={"https://vk.com/dextechnology"} target={"_blank"} aria-label={"vkontakte"}>
          <Icon alt={""} src={vk} />
        </IconContainer>
        <IconContainer href={"https://www.instagram.com/dex.it/"} target={"_blank"} aria-label={"instagram"}>
          <Icon alt={""} src={instagram} />
        </IconContainer>
      </SocialNetworkContainer>
    );
  }
}

const SocialNetworkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
  }
`;

const Icon = styled.img`
  max-width: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //padding: 10px;
`;

const IconContainer = styled.a`
  position: relative;
  background: white;
  opacity: 0.6;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;

  &:hover {
    opacity: 1;
  }
  margin: 10px;

  @media (max-width: 767px) {
    margin: 8px;
  }
`;
