import * as React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { ElementInfo } from "./blockInfo/elementInfo";
import people from "../shared/img/people.svg";
import time from "../shared/img/time.svg";
import distance from "../shared/img/distance.svg";
import map from "../shared/img/white_map.svg";
import medal from "../shared/img/medal.svg";
import greenMedal from "../shared/img/greenMedal.svg";
import vitamin from "../shared/img/vitamin.svg";
import whiteVitamin from "../shared/img/whiteVitamin.svg";
import live from "../shared/img/live.svg";
import whiteLive from "../shared/img/whiteLive.svg";
import water from "../shared/img/water.svg";
import greenWater from "../shared/img/greenWater.svg";

export const SectionBlockInfo: React.FC = (props) => {
  return (
    <Container>
      <div className={"container"}>
        <ElementInfo
          background={"#EAFAFF"}
          color={"#004836"}
          title={"Более 2000"}
          description={"Участников забега"}
          image={people}
        />
        <ElementInfo
          background={theme.colors.green}
          color={"#ffffff"}
          title={"Регистрация 9:00"}
          description={"Старт 10:00"}
          image={time}
        />
        <ElementInfo
          background={window.innerWidth > 767 && window.innerWidth < 992 ? theme.colors.green : "#EAFAFF"}
          color={window.innerWidth > 767 && window.innerWidth < 992 ? "#ffffff" : "#004836"}
          title={"2, 5, 10 и 21 км"}
          description={"+ Детский забег"}
          image={distance}
        />
        <ElementInfo
          background={window.innerWidth > 767 && window.innerWidth < 992 ? "#EAFAFF" : theme.colors.green}
          color={window.innerWidth > 767 && window.innerWidth < 992 ? "#004836" : "#ffffff"}
          title={"г. Тирасполь"}
          description={"Площадь Суворова"}
          image={map}
        />
        <ElementInfo
          background={window.innerWidth < 992 ? "#EAFAFF" : theme.colors.green}
          color={window.innerWidth > 991 ? "#ffffff" : "#004836"}
          title={"Медали и кубки"}
          description={"Призы каждому участнику"}
          image={window.innerWidth < 992 ? greenMedal : medal}
        />
        <ElementInfo
          background={window.innerWidth < 992 ? theme.colors.green : "#EAFAFF"}
          color={window.innerWidth < 992 ? "#ffffff" : "#004836"}
          title={"Почетные гости"}
          description={"Со всего мира!"}
          image={window.innerWidth < 992 ? whiteLive : live}
        />
        <ElementInfo
          background={window.innerWidth < 768 ? "#EAFAFF" : theme.colors.green}
          color={window.innerWidth > 767 ? "#ffffff" : "#004836"}
          title={"Море позитива!"}
          description={"Для взрослых и детей"}
          image={window.innerWidth < 768 ? greenWater : water}
        />
        <ElementInfo
          background={window.innerWidth < 768 ? theme.colors.green : "#EAFAFF"}
          color={window.innerWidth < 768 ? "#ffffff" : "#004836"}
          title={"Витаминизация"}
          description={"Фрукты для участников "}
          image={window.innerWidth < 768 ? whiteVitamin : vitamin}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: ${theme.colors.white};
  padding-top: 80px;
  padding-bottom: 100px;
  .container {
    display: flex;
    flex-wrap: wrap;
  }
`;
