import React, { useRef, useState } from "react";
import { styled } from "../../core/styledComponents";
import playIcon from "../../shared/icons/playIcon.svg";
import pauseIcon from "../../shared/icons/pause.svg";
import BackgroundVideoImg from "../../shared/img/videoCover.png";
import video from "../../shared/video/run-video-new.mp4";

export const VideoBlock = () => {
  const [play, setPlay] = useState(false);
  const [start, setStart] = useState(true);

  const videoRef = useRef<any>();
  const onStart = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setPlay(true);
      setStart(false);
    }
  };

  const onPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlay(false);
    }
  };

  const onClickVideo = () => {
    if (play) {
      onPause();
    } else {
      onStart();
    }
  };

  return (
    <Container>
      {!play && start && (
        <PlayContainer onClick={onClickVideo}>
          <Play src={playIcon} alt={"play"} />
        </PlayContainer>
      )}
      {!start && (
        <PlayContainer onClick={onClickVideo} as="a">
          {play ? <Play src={pauseIcon} /> : <Play src={playIcon} alt={"play"} />}
        </PlayContainer>
      )}
      {!play && start && (
        <StubImage>
          <img src={BackgroundVideoImg} alt="video" />
        </StubImage>
      )}
      <Video ref={videoRef} onClick={onClickVideo}>
        <source type="video/mp4" src={video} />
      </Video>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 450px;

  & > a {
    display: none;
  }

  &:hover > a {
    display: flex;
  }
  overflow: hidden;

  @media (max-width: 1199px) {
    height: 380px;
  }
`;

const StubImage = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  border: 1px solid #e9e7e7;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PlayContainer: any = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
  user-select: false;
`;

const Play = styled.img`
  width: 65px;
  height: 65px;
  user-select: false;
`;
