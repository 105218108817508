import * as React from "react";
import cloud from "../shared/img/cloud.png";
import it_run from "../shared/img/text.png";
import { Calendar } from "../shared/icons/Calendar";
import { GreenButton } from "../ui/GreenButton";
import { MainRoad } from "../shared/icons/MainRoad";
import { PeopleOne } from "../shared/icons/PeopleOne";
import { PeopleThree } from "../shared/icons/PeopleThree";
import { PeopleTwo } from "../shared/icons/PeopleTwo";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";

interface IState {
  translateMan: string;
  translateWoman: string;
  translate: string;
  offsetX: number;
  offsetY: number;
}

export class SectionHello extends React.Component<{}, IState> {
  public readonly state = {
    translateWoman: "translate3d(0)",
    translateMan: "translate3d(0)",
    translate: "translate3d(0)",
    offsetX: 0,
    offsetY: 0,
  };

  public render() {
    return (
      <Container onMouseMoveCapture={this.mouseMove}>
        <Content className={"container"}>
          <PeopleImage transform={this.state.translate}>
            <PeopleOne />
          </PeopleImage>
          <PeopleWomenImage transform={this.state.translateWoman}>
            <PeopleTwo />
          </PeopleWomenImage>
          <PeopleMenImage transform={this.state.translateMan}>
            <PeopleThree />
          </PeopleMenImage>
          <RoadImage>
            <MainRoad />
          </RoadImage>
          <CloudContainer backgroundImage={cloud} />
          <div className={"col-lg-6"}>
            <TimeBlock>
              <Calendar />
              <span>8 октября, 09:00</span>
            </TimeBlock>
            <Title>
              <img src={it_run} alt="" />
            </Title>
            <Description>По центру Тирасполя</Description>
            <div className="href-scroll">
              <GreenButton className="first">Бесплатная регистрация</GreenButton>
            </div>
          </div>
        </Content>
      </Container>
    );
  }

  //
  // private scroll = (event) => {
  //     event.preventDefault();
  //
  //     window.scrollTo(100, document.getElementById('reg'))
  // };

  private mouseMove = (event) => {
    const offsetX = 1 - event.pageX / 100;
    const offsetY = 1 - event.pageY / 80;

    const offsetXMan = 1 - event.pageX / 80;
    const offsetYMan = 1 - event.pageY / 40;

    const offsetXWoman = 1 - event.pageX / 100;
    const offsetYWoman = 1 - event.pageY / 60;

    this.setState({
      translate: "translate3d(" + Math.round(offsetX) + "px," + Math.round(offsetY) + "px, 0px)",
      translateMan: "translate3d(" + Math.round(offsetXMan) + "px," + Math.round(offsetYMan) + "px, 0px)",
      translateWoman: "translate3d(" + Math.round(offsetXWoman) + "px," + Math.round(offsetYWoman) + "px, 0px)",
    });
  };
}

const Container = styled.div`
  overflow: hidden;
  position: relative;
  background: ${theme.colors.white};
  height: 100vh;
  min-height: 750px;
  padding: 100px 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @media (max-width: 991px) {
    align-items: flex-start;
    margin-top: 80px;

    .col-lg-6 {
      width: auto;
    }
  }

  @media (max-width: 767px) {
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
  }

  .col-lg-6 {
    z-index: 999;
  }

  span {
    width: 100%;
  }
`;

const TimeBlock = styled.div`
  font-family: GilroyMedium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  color: ${theme.colors.textGreen};
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  span {
    margin-left: 20px;
  }

  @media (max-width: 1199px) {
    margin-bottom: 40px;
  }
`;

const Title = styled.div`
  font-family: GilroyBlack, sans-serif;
  font-weight: 900;
  font-size: 110px;
  line-height: 1;
  color: ${theme.colors.textGreen};
  margin-bottom: 8px;

  & > img {
    width: 100%;
  }
  span {
    color: ${theme.colors.green};
  }

  @media (max-width: 1199px) {
    font-size: 80px;
  }

  @media (max-width: 767px) {
    font-size: 60px;
  }
`;

const Description = styled.div`
  font-family: GilroyMedium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  /* line-height: 220%; */
  color: ${theme.colors.textGreen};
  margin-bottom: 60px;

  @media (max-width: 1199px) {
    font-size: 36px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const PeopleWomenImage = styled.div<{ transform }>`
  position: absolute;
  left: -144px;
  bottom: 91px;
  z-index: 2;
  transform: ${(props) => props.transform};

  @media (max-width: 1199px) {
    width: 270px;
    left: 30px;
    bottom: -34px;
    display: flex;
  }

  @media (max-width: 767px) {
    width: 220px;
    left: -40px;
    bottom: -61px;
  }
`;

const PeopleMenImage = styled.div<{ transform }>`
  position: absolute;
  left: -193px;
  bottom: 50px;
  z-index: 2;
  transform: ${(props) => props.transform};

  @media (max-width: 1199px) {
    width: 602px;
    left: 0px;
    bottom: -62px;
    display: flex;
  }

  @media (max-width: 767px) {
    width: 511px;
    left: -89px;
    bottom: -75px;
  }
`;

const PeopleImage = styled.div<{ transform }>`
  position: absolute;
  left: 150px;
  bottom: 220px;
  z-index: 2;
  transform: ${(props) => props.transform};

  @media (max-width: 1199px) {
    width: 230px;
    left: 197px;
    bottom: 121px;
    display: flex;
  }

  @media (max-width: 767px) {
    width: 170px;
    left: 96px;
    bottom: 49px;
  }
`;

const RoadImage = styled.div`
  position: absolute;
  left: -1440px;
  bottom: -1940px;
  z-index: 1;
  animation: rotate 100s linear infinite;

  @media (max-width: 1199px) {
    display: flex;
  }

  @media (max-width: 767px) {
    left: -933px;
    bottom: -1612px;
    width: 1992px;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
`;

const CloudContainer = styled.div<{ backgroundImage: string }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 170px;

  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
`;
