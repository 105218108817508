import {RouterState} from "connected-react-router";
import {Action, combineReducers} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {MailReducer} from "../../modules/mailReduser";

export const mainReducer = combineReducers({
    mail: MailReducer
});

export interface IAppState extends ReturnType<typeof mainReducer> {
    router: RouterState;
}

export interface IAppDispatch extends ThunkDispatch<IAppState, Error, Action> {
}

// export type SimpleThunk = (dispatch: IAppDispatch, getState: () => IAppState) => Promise<void>;
export interface IThunkAction extends ThunkAction<Promise<void>, IAppState, Error, Action> {
}
