import React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { SocialNetwork } from "./footer/SocialNetwork";
import { MailState } from "./footer/selectors";
import { connect } from "react-redux";
import { Check } from "../shared/icons/Check";
import { FormInput } from "./footer/FormInput";
import { LogoDex } from "../shared/icons/LogoDex";
// import { LogoRunIt } from "../shared/icons/LogoRunIt";

interface IProps extends ReturnType<typeof MailState> {}

class FooterContainer extends React.Component<IProps> {
  public render() {
    return (
      <FooterSection>
        <FormRegister visible={this.props.request === "successful"}>
          <Block className={"container"}>
            <div className={"col-lg-9 col-md-10"}>
              <Title>Бесплатная регистрация!</Title>
              <FormInput />
            </div>
            <SocialNetwork />
            <BlockLogo>
              <a href={"https://dex-it.ru"}>
                <LogoDex color={"white"} />
              </a>
              <span>
                <b>ИТ ЗА!БЕГ</b> 8 октября (воскресенье) в 9:00
                <div style={{ marginTop: 10 }}>Старт от кинотеатра "Тирасполь"</div>
              </span>
            </BlockLogo>
          </Block>
        </FormRegister>
        <FormMap visible={this.props.request === "successful"}>
          <Block className={"container"}>
            <div className={"col-lg-9 col-md-10"}>
              <Title>Спасибо за регистрацию</Title>
              <Description>{this.props.name}, мы ждем вас 8 октября!</Description>
              <BlockRegisterSuccessful>
                <Check /> <span>Регистрация прошла успешно</span>
              </BlockRegisterSuccessful>
            </div>
            <SocialNetwork />
            <BlockLogo>
              <a href={"https://dex-it.ru"}>
                <LogoDex color={"white"} />
              </a>
              <span>
                <b>ИТ ЗА!БЕГ</b>8 октября (воскресенье) в 9:00
                <div style={{ marginTop: 10 }}>Старт от кинотеатра "Тирасполь"</div>
              </span>
            </BlockLogo>
          </Block>
        </FormMap>
      </FooterSection>
    );
  }
}

export const Footer = connect(MailState, undefined)(FooterContainer);

const FooterSection = styled.div`
  @media (min-width: 992px) {
    position: fixed;
    bottom: 0;
    z-index: -1;
    height: 740px;
  }
  background: ${theme.colors.green};
  width: 100%;
  padding: 100px 0 50px 0;

  @media (max-width: 767px) {
    padding: 60px 0 50px 0;
  }
`;

const FormMap = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const FormRegister = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "none" : "block")};
`;

const Title = styled.div`
  font-family: MontserratSemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 43px;
  line-height: 1.4;
  color: ${theme.colors.white};
  margin-bottom: 50px;

  @media (max-width: 767px) {
    font-size: 36px;
  }

  @media (max-width: 420px) {
    font-size: 28px;
  }
`;

const Description = styled.div`
  font-family: GilroyRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.2;
  color: ${theme.colors.white};
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 28px;
  }
  @media (max-width: 420px) {
    font-size: 24px;
  }
`;

const BlockLogo = styled.div`
  display: flex;
  margin-top: 100px;
  width: 100%;
  align-items: flex-end;

  @media (max-width: 767px) {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
  }

  a {
    margin-bottom: 5px;
    margin-right: 20px;
    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  span {
    font-family: GilroyRegular, sans-serif;
    padding: 0 30px;
    font-size: 18px;
    border-radius: 8px;
    line-height: 1;
    letter-spacing: 0.04em;
    color: ${theme.colors.white};
    margin-left: 16px;

    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 30px;
    }
  }
`;

const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BlockRegisterSuccessful = styled.div`
  display: flex;
  align-items: center;
  margin: 100px 0;

  span {
    font-family: MontserratSemiBold, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 1.2;
    color: ${theme.colors.white};
    padding: 0 40px;

    @media (max-width: 767px) {
      font-size: 24px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
`;
