import React from "react";
import { styled } from "../../core/styledComponents";
import { NavLink } from "../../ui/NavLink";

interface ILinkBlockProps {
  open: string;
  onClick?: () => void;
  notTop: string;
}

interface IState {
  link: string;
}

export class LinkBlock extends React.Component<ILinkBlockProps, IState> {
  public readonly state = { link: "" };

  public render() {
    return (
      <NavContainer className={this.props.open}>
        <NavLink
          href="#what"
          onClick={this.clickLink}
          activeStyle={this.state.link === "#what"}
          className={this.props.notTop}
        >
          Что такое забег?
        </NavLink>
        <NavLink
          href="#place"
          onClick={this.clickLink}
          activeStyle={this.state.link === "#place"}
          className={this.props.notTop}
        >
          Место проведения
        </NavLink>
      </NavContainer>
    );
  }

  private clickLink: React.ReactEventHandler<HTMLAnchorElement> = (e) => {
    this.setState({ link: "#" + e.currentTarget.href.split("#")[1] });

    if (this.props.onClick) {
      this.props.onClick();
    }
  };
}

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 999;
  @media (max-width: 768px) {
    padding-top: 20px;
    flex-direction: column;
    display: none;
  }
  &.open {
    @media (max-width: 768px) {
      display: flex;
    }
  }
`;
