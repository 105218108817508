import {createSelector} from "reselect";
import {IAppDispatch, IAppState} from "../../core/store/mainReducer";
import {MailActionAsync} from "../../modules/mailActionAsync";

export const MailState = createSelector(
    (state: IAppState) => state.mail.request,
    (state: IAppState) => state.mail.name,
    (request, name) => ({request, name})
);

export const MailDispatch = (dispatch: IAppDispatch) => ({
    sendMail: (name: string, surmane: string, email: string, work: string) =>
        dispatch(MailActionAsync.SendMail(name, surmane, email, work))
});
