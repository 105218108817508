import * as React from "react";

export const PeopleThree: React.FC = () => (
  <svg width="972" height="466" viewBox="0 0 972 466" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M845.065 267.391C848.195 273.539 847.111 278.949 840.014 282.98L845.065 267.391C831.645 241.034 740.771 201.1 714.062 197.934C687.91 194.834 669.939 181.744 678.109 167.79C661.434 165.703 655.839 164.495 678.227 167.59C680.373 164.033 684.221 160.422 690.068 156.908C703.631 148.76 877.6 131.678 970.905 146.901C941.905 159.401 949.405 189.401 949.405 189.401C912.285 158.326 863.81 172.772 750.557 173.305C731.689 173.394 706.635 171.091 688.24 168.998C695.232 169.995 703.881 171.253 714.403 172.815C824.728 189.193 850.986 234.6 850.986 234.6L850.498 250.618L845.065 267.391Z"
      fill="url(#paint0_linear)"
    />
    <path
      opacity="0.6"
      d="M853.747 441.903C851.319 454.122 768.066 447.874 667.795 427.947C567.525 408.02 496.45 382.478 498.879 370.259C501.307 358.04 576.319 363.77 676.589 383.697C776.859 403.624 856.176 429.684 853.747 441.903Z"
      fill="rgba(0, 0, 0, 0.15)"
    />
    <path
      d="M523.62 282.976C528.094 283.603 528.882 285.751 534.072 288.433C531.858 292.677 532.941 295.103 531.882 297.249C531.603 297.814 517.769 312.841 512.94 317.888C511.062 319.851 509.694 318.964 510.466 315.262L516.048 288.185C517.101 285.348 519.147 282.348 523.62 282.976Z"
      fill="#E89889"
    />
    <path
      d="M809.662 184.437C792.976 210.468 751.685 201.341 716.477 208.922C708.715 207.887 720.228 177.067 735.64 160.55C735.64 160.55 773.905 176.001 788.229 164.158C800.615 153.918 809.656 137.656 820.506 118.508C820.506 118.508 819.42 108.392 822.1 98.2443C822.791 95.6287 825.516 95.8367 825.9 98.0563L826.776 105.436L836.336 94.6341C837.892 92.7505 840.136 94.4462 838.94 96.4691L833.795 104.421L842.183 97.7228C844.46 96.1178 845.566 98.616 844.288 99.7789L836.26 106.617L843.026 102.395C844.739 101.427 846.346 103.828 843.92 105.663L836.669 110.917L842.734 107.743C844.814 107.719 844.181 110.883 838.299 114.156C838.299 114.156 833.92 120.903 828.924 123.114C828.924 123.114 823.648 162.619 809.662 184.437Z"
      fill="#E89889"
    />
    <path
      d="M799.742 410.32C798.136 405.067 799.581 402.434 797.759 394.345C804.444 393.288 808.447 390.721 809.333 390.829C810.219 390.938 838.502 392.015 848.467 393.232C852.343 393.705 852.626 395.988 847.859 398.215L814.002 413.186C809.923 414.373 801.348 415.574 799.742 410.32Z"
      fill="#F2AEA1"
    />
    <path
      d="M799.742 410.32C798.136 405.066 799.581 402.433 797.759 394.344C799.382 393.995 799.26 398.41 803.165 398.903C814.963 400.394 832.014 394.527 828.336 390.965C858.17 389.965 852.626 395.987 847.859 398.214L814.002 413.185C809.923 414.373 801.348 415.573 799.742 410.32Z"
      fill="#005640"
    />
    <path
      d="M523.793 282.9C528.266 283.528 529.055 285.675 534.244 288.358C533.042 292.965 532.26 290.131 526.497 295.335C521.704 299.663 516.93 310.692 518.503 311.938C511.055 327.885 507.719 319.471 508.97 314.126L514.833 287.304C515.886 284.466 519.32 282.273 523.793 282.9Z"
      fill="#005640"
    />
    <path
      d="M653.684 339.426C718.688 331.394 736.112 251.78 736.112 251.78C736.112 251.78 676.73 246.793 643.899 258.799C634.884 262.096 631.536 283.3 616.256 285.14C600.976 286.981 586.869 269.536 534.053 288.355C532.21 291.672 531.444 293.633 531.881 297.251C555.809 317.713 591.018 347.17 653.684 339.426Z"
      fill="#005640"
    />
    <path
      d="M778.551 266.285C731.06 238.963 646.415 243.844 637.827 257.376C620.052 285.383 636.161 310.574 671.92 319.177C696.063 324.985 719.705 325.412 745.23 320.909C745.23 320.909 751.509 341.176 781.294 372.723C794.697 386.919 796.915 392.989 797.639 394.402C802.586 394.325 805.186 393.849 808.969 390.86C808.969 390.86 826.042 293.607 778.551 266.285Z"
      fill="#006047"
    />
    <path
      d="M787.783 200.795C773.457 207.238 752.11 210.998 720.135 203.962C715.66 197.536 701.47 161.808 721.791 157.864C742.112 153.921 747.536 154.521 782.363 166.829C788.765 176.686 787.881 188.543 787.783 200.795Z"
      fill="#00B183"
    />
    <path
      d="M696.504 150.144C694.715 149.121 676.667 143.091 674.148 148.847C671.63 154.603 661.314 184.691 661.314 184.691C661.314 184.691 658.594 217.142 648.524 234.536C639.358 250.369 637.8 256.167 637.8 256.167C658.113 246.721 716.255 257.387 731.899 250.214C731.899 250.214 749.567 218.481 749.253 196.272C748.94 174.062 740.857 163.307 731.184 156.848C721.51 150.39 714.159 171.565 711.751 171.437C708.015 171.24 699.828 152.044 696.504 150.144Z"
      fill="#00B183"
    />
    <path
      d="M603.392 170.301C629.869 152.665 643.93 144.542 675.355 146.998C680.92 152.507 685.337 185.824 663.081 189.696C663.081 189.696 638.206 192.739 613.447 197.474C597.663 200.493 573.019 215.469 552.812 224.193C552.812 224.193 550.277 233.791 544.061 241.88C542.459 243.965 539.944 242.569 540.381 240.371L542.217 233.239L529.224 238.883C527.063 239.916 525.543 237.374 527.412 236.054L535.177 231.062L524.782 233.474C522.037 233.934 521.89 231.162 523.525 230.664L533.628 227.965L526.063 226.324C523.814 225.693 523.433 223.743 526.47 223.57L534.794 223.854L527.864 222.742C525.878 221.844 527.64 220.563 534.415 220.173C534.415 220.173 541.331 216.606 546.88 216.796C546.88 216.796 580.565 185.505 603.392 170.301Z"
      fill="#F6B4A8"
    />
    <path
      d="M602.703 168.128C629.18 150.492 647.756 142.765 679.181 145.22C684.747 150.729 693.274 191.082 671.018 194.954C671.018 194.954 641.455 194.092 616.695 198.828C605.986 186.922 604.771 180.171 602.703 168.128Z"
      fill="#00B183"
    />
    <path
      d="M713.521 28.7677C736.985 35.5263 756.716 60.4505 750.243 78.3606C739.901 106.974 714.603 122.064 714.603 122.064C714.603 122.064 696.841 133.2 686.22 125.513C676.316 118.346 671.131 108.953 662.259 100.528C656.782 95.3281 632.063 93.3968 633.454 85.9405C634.519 80.2356 653.321 80.0942 654.572 74.4276C656.181 67.1419 636.67 71.3418 640.251 64.8028C642.916 59.9355 653.578 61.2657 655.527 56.0667C657.819 49.9492 639.122 51.3675 641.885 45.4493C643.838 41.2667 656.626 42.4778 658.049 38.085C659.66 33.1149 656.638 34.7118 652.566 31.4606C630.076 13.5003 683.194 20.0323 713.521 28.7677Z"
      fill="#2C3434"
    />
    <path
      d="M679.217 146.352C681.427 142.726 681.755 146.525 687.434 146.915C686.108 166.249 730.006 163.434 739.66 178.15L735.358 183.053C720.044 167.07 679.908 171.651 679.217 146.352Z"
      fill="#FFA449"
    />
    <path
      d="M720.034 153.035C715.71 152.967 716.252 151.018 712.301 152.504C730.588 162.014 723.839 168.35 735.233 181.451L741.824 180.429C732.44 170.397 734.786 157.446 720.034 153.035Z"
      fill="#FFA449"
    />
    <ellipse
      rx="10.4573"
      ry="14.0459"
      transform="matrix(0.59324 -0.805025 0.784223 0.62048 745.985 189.373)"
      fill="white"
    />
    <ellipse
      rx="9.22701"
      ry="11.3147"
      transform="matrix(0.59324 -0.805025 0.784223 0.62048 746.29 189.617)"
      fill="#FFA449"
    />
    <path
      d="M748.718 180.405C757.08 184.139 758.15 192.583 755.111 196.706C754.061 198.132 752.598 199.083 750.929 199.556C750.929 199.556 755.465 196.698 754.554 189.923C753.694 183.53 748.718 180.405 748.718 180.405Z"
      fill="#EDB31D"
    />
    <path
      d="M657.645 100.464C637.487 96.114 624.996 80.6052 641.113 86.754C657.229 92.9029 657.529 89.3068 666.013 95.0386C672.511 99.4287 700.232 103.118 706.12 108.304C715.56 116.62 710.335 127.059 710.335 127.059C710.335 127.059 691.866 132.765 681.268 125.065C671.386 117.884 669.582 103.04 657.645 100.464Z"
      fill="#1D2323"
    />
    <path
      d="M762.778 102.045C760.307 90.1896 749.725 78.6577 749.725 78.6577C736.667 75.3539 712.561 83.5685 711.326 100.501C710.575 110.802 711.043 114.715 704.96 109.3C698.011 103.114 693.454 111.99 693.64 116.835C693.826 121.68 695.392 124.669 702.494 126.878C707.232 128.352 706.716 122.645 704.621 132.188C702.768 140.627 692.201 152.259 692.201 152.259C707.721 163.559 722.875 157.385 722.875 157.385C719.343 150.047 725.293 133.259 725.293 133.259C725.293 133.259 750.433 144.729 755.221 138.625C762.733 129.046 766.078 117.875 762.778 102.045Z"
      fill="#F6B4A8"
    />
    <path
      d="M749.418 108.527C751.115 108.707 755.979 106.537 755.642 103.723C755.062 98.868 749.389 102.378 746.485 95.2278"
      stroke="#DB7B6A"
      strokeWidth="0.785149"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M737.405 89.8527C738.961 93.1181 730.058 91.3754 725.905 98.7069C725.905 98.7069 725.267 93.6099 729.379 90.7532C733.214 88.0886 737.405 89.8527 737.405 89.8527Z"
      fill="#2C3434"
    />
    <path
      d="M746.208 87.2627C746.292 90.8581 752.6 84.7682 758.925 89.4528C758.925 89.4528 757.864 84.9294 753.398 84.2089C749.232 83.5369 746.208 87.2627 746.208 87.2627Z"
      fill="#2C3434"
    />
    <path
      d="M751.627 102.95C749.391 102.797 746.815 98.464 745.781 94.6034C747.275 98.7214 749.645 99.1612 753.262 100.904C754.266 101.388 754.685 103.16 751.627 102.95Z"
      fill="#FFE3E2"
      fillOpacity="0.19"
    />
    <path
      d="M722.415 155.812C718.883 148.475 731.066 130.169 731.066 130.169C720.933 125.562 713.854 151.316 699.015 151.917C703.013 154.811 709.769 170.657 711.656 170.921C713.543 171.186 722.415 155.812 722.415 155.812Z"
      fill="#E89889"
    />
    <path
      d="M654.155 70.6651C664.365 67.3405 664.697 69.117 679.986 67.9463C679.986 67.9463 662.122 64.1586 651.205 66.8144C641.117 69.2685 641.729 65.4268 640.832 64.0391C640.832 64.0391 639.85 65.4456 639.905 66.5037C640.183 71.908 643.945 73.9898 654.155 70.6651Z"
      fill="#1D2323"
    />
    <path
      d="M696.846 150.692C698.979 150.991 703.109 151.95 706.155 154.245C708.778 162.821 712.21 171.918 711.53 171.823C710.851 171.727 697.912 155.609 697.912 155.609L696.846 150.692Z"
      fill="#E1EBFA"
    />
    <path
      d="M695.136 144.392C699.325 148.605 705.113 153.771 705.113 153.771C701.316 153.583 695.084 157.509 690.756 160.712C687.528 156.47 686.17 150.66 682.946 145.382C684.721 142.193 688.154 141.878 695.136 144.392Z"
      fill="white"
    />
    <path
      d="M722.607 155.477C720.475 155.178 720.024 156.167 716.463 157.543C715.922 161.398 710.805 171.471 711.45 171.812C712.095 172.153 719.902 160.451 719.902 160.451L722.607 155.477Z"
      fill="#E1EBFA"
    />
    <path
      d="M721.922 152.125C720.45 155.428 716.708 157.577 716.708 157.577C722.542 160.425 723.161 161.348 726.463 165.554C729.456 164.364 727.438 159.898 730.086 155.181C727.21 152.156 727.724 151.195 721.922 152.125Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M716.317 128.08C720.612 137.627 729.853 144.886 736.812 145.083C746.928 145.37 759.721 144.894 764.243 136.332C770.792 123.931 764.198 111.564 764.198 111.564C763.863 112.425 764.052 113.695 762.485 115.319C760.919 116.943 760.033 112.015 756.762 111.605C752.075 111.018 744.102 113.394 740.732 115.321C736.098 117.971 737.748 121.289 733.778 121.628C732.608 121.247 730.808 121.19 730.061 120.761C728.603 119.926 713.515 118.946 709.012 97.2471C708.138 93.037 702.728 101.671 702.728 101.671C702.728 101.671 710.896 114.193 710.602 118.817C710.976 121.591 715.216 125.696 716.317 128.08C716.454 128.377 716.25 127.931 716.317 128.08Z"
      fill="#2C3434"
    />
    <path
      d="M746.242 118.505C750.763 119.01 753.227 118.595 757.332 116.636C758.556 122.638 749.693 123.474 746.242 118.505Z"
      fill="#FFF6F6"
    />
    <path
      d="M736.811 145.082C729.852 144.884 719.48 140.218 715.184 130.671C714.084 128.287 709.03 121.836 707.841 109.749C707.841 109.749 716.917 134.789 734.571 139.954C747.585 143.761 764.241 136.331 764.241 136.331C759.72 144.893 746.927 145.369 736.811 145.082Z"
      fill="#1D2323"
    />
    <ellipse
      cx="736.106"
      cy="99.5867"
      rx="1.56641"
      ry="1.95802"
      transform="rotate(-28.4682 736.106 99.5867)"
      fill="#343232"
    />
    <ellipse
      cx="753.86"
      cy="95.2071"
      rx="1.56641"
      ry="1.95802"
      transform="rotate(-26.2656 753.86 95.2071)"
      fill="#343232"
    />
    <path
      d="M602.284 168.207C603.805 165.598 605.434 165.067 607.69 164.516C610.112 177.066 614.003 188.291 623.283 197.844C621.285 198.97 619.214 199.103 616.674 198.941C607.051 189.058 603.74 179.04 602.284 168.207Z"
      fill="white"
    />
    <path
      d="M788.287 200.421C786.114 202.517 785.318 203.012 782.909 203.593C783.993 190.858 782.292 176.37 775.961 164.653C778.16 164.575 780.085 165.664 782.485 166.51C789.056 178.638 789.248 189.978 788.287 200.421Z"
      fill="#E1EBFA"
    />
    <path
      d="M709.864 124.233C708.8 125.298 705.594 124.293 702.747 122.36C700.143 120.592 698.191 116.952 701.315 114.691C703.972 112.768 708.83 117.455 706.284 120.405C709.176 123.295 710.929 123.168 709.864 124.233Z"
      fill="white"
    />
    <path
      d="M623.757 233.586C509.405 196.901 309.405 159.401 0.405447 310.901C0.405417 271.901 32.4054 207.401 115.905 177.401C199.405 147.401 475.905 124.401 644.905 194.401C659.959 200.636 901.052 299.583 835.22 218.042C877.487 239.898 890.52 303.693 838.871 299.761C753.976 293.298 669.256 248.183 623.757 233.586Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M708.351 230.471C711.357 231.655 713.394 233.498 714.464 235.998C715.547 238.491 715.518 241.188 714.375 244.088C713.816 245.507 713.069 246.704 712.134 247.681C711.214 248.651 710.17 249.377 709.002 249.858C706.525 250.9 703.789 250.83 700.793 249.65L699.642 252.571L694.276 250.456L695.427 247.535C692.389 246.338 690.32 244.514 689.22 242.063C688.119 239.611 688.117 236.994 689.214 234.21C689.794 232.739 690.558 231.499 691.505 230.491C692.453 229.482 693.522 228.724 694.713 228.215C697.222 227.125 699.979 227.172 702.985 228.356L704.136 225.435L709.502 227.55L708.351 230.471ZM701.014 232.752C699.628 232.206 698.349 232.24 697.177 232.854C696.016 233.473 695.108 234.613 694.454 236.275C693.853 237.799 693.78 239.164 694.234 240.37C694.692 241.565 695.678 242.461 697.191 243.058L701.22 232.833L701.014 232.752ZM706.587 234.948L702.558 245.173L702.796 245.266C704.119 245.788 705.348 245.771 706.484 245.216C707.635 244.654 708.519 243.59 709.136 242.024C709.778 240.394 709.891 238.959 709.475 237.719C709.074 236.472 708.111 235.549 706.587 234.948Z"
      fill="#00B183"
    />
    <path
      d="M744.472 241.393L750.142 243.325L742.127 266.843L736.458 264.911L741.511 250.083L728.01 262.032L722.341 260.1L730.355 236.582L736.025 238.514L730.966 253.358L744.472 241.393Z"
      fill="#00B183"
    />
    <path
      d="M777.939 277.732L772.135 276.393L774.468 266.283L765.871 264.3L763.539 274.41L757.702 273.063L763.288 248.853L769.124 250.199L766.907 259.81L775.504 261.794L777.721 252.183L783.524 253.522L777.939 277.732Z"
      fill="#00B183"
    />
    <path
      d="M811.516 259.028L817.411 260.085L813.026 284.541L807.13 283.484L809.895 268.065L798.345 281.909L792.45 280.851L796.835 256.395L802.731 257.453L799.963 272.889L811.516 259.028Z"
      fill="#00B183"
    />
    <path
      d="M834.059 261.772L833.376 281.362C835.356 282.278 837.355 282.335 839.355 282.392L840.038 261.98L845.836 262.182L845.128 282.558C847.019 282.493 849.865 282.194 851.074 281.728L851.75 262.389L857.514 262.59L856.669 286.891C856.669 286.891 851.117 288.889 842.636 288.847C834.155 288.805 826.727 286.938 826.727 286.938L828.261 261.57L834.059 261.772Z"
      fill="#00B183"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="840.566"
        y1="194.764"
        x2="1055.92"
        y2="186.37"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDF1F8" />
        <stop offset="0.404969" stopColor="#DDF1F8" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="607.016"
        y1="90.3797"
        x2="-116.272"
        y2="525.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0729167" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
