import * as React from "react";

export const PeopleOne: React.FC = () => (
  <svg width="280" height="330" viewBox="0 0 280 330" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.6"
      d="M176.533 314.99C175.296 321.215 135.642 318.58 87.9635 309.105C40.2849 299.63 2.63652 286.902 3.8736 280.677C5.11068 274.453 44.7647 277.088 92.4434 286.563C140.122 296.038 177.77 308.765 176.533 314.99Z"
      fill="rgba(0, 0, 0, 0.15)"
    />
    <path
      d="M158.1 94.2305C159.873 95.7669 162.041 99.5069 164.358 99.8872L169.581 106.829L164.858 109.917L152.351 111.352L130.91 85.7949C130.91 85.7949 142.967 87.025 143.687 83.3364C144.674 78.2866 146.203 67.6855 146.203 67.6855C146.203 67.6855 141.576 68.7327 140.682 67.4279C139.034 65.0201 135.575 58.2775 138.502 57.7676C140.505 57.4188 140.065 54.0496 140.065 54.0496C142.694 55.438 141.738 45.507 144.382 44.9993C147.37 44.4254 159.327 47.8545 161.762 46.3281C164.744 44.4587 172.895 47.8188 173.891 50.4533C175.447 54.5701 173.67 60.9526 173.232 63.6212C170.22 81.9755 165.177 80.4178 158.623 79.7035C158.623 79.7035 156.327 92.694 158.1 94.2305Z"
      fill="#F6B4A8"
    />
    <path
      d="M161.039 61.3257C159.875 60.3621 159.214 59.8155 157.441 60.7621C158.663 60.4023 159.514 60.5977 161.061 61.3439L161.039 61.3257Z"
      stroke="black"
      stroke-width="0.728036"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M168.638 62.5616C170.176 61.534 170.883 61.9378 172.068 63.1129C170.714 62.3719 169.963 62.3716 168.638 62.5616Z"
      stroke="black"
      stroke-width="0.728036"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M161.098 72.4307C164.075 75.3412 165.68 74.1805 166.884 72.9653C165.3 75.6486 162.397 75.182 161.098 72.4307Z"
      stroke="#E26662"
      stroke-width="0.743198"
      stroke-linejoin="round"
    />
    <path
      d="M162.556 69.0929C163.309 69.5341 166.299 70.1989 166.988 68.9238C168.271 66.5534 165.423 66.4731 165.575 62.5281"
      stroke="#DB7B6A"
      stroke-width="0.512425"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M163.169 58.5346C163.062 59.8524 159.651 57.5472 156.124 58.9885C156.124 58.9885 157.061 57.287 159.507 57.2202C161.788 57.1579 163.169 58.5346 163.169 58.5346Z"
      fill="#1F2121"
    />
    <path
      d="M168.194 59.1408C167.866 60.4212 171.32 59.0252 173.685 61.4383C173.685 61.4383 173.669 59.713 171.736 58.9233C169.933 58.1867 168.194 59.1408 168.194 59.1408Z"
      fill="#1F2121"
    />
    <path
      d="M165.722 67.8575C165.012 66.958 164.547 64.5661 164.927 62.4323C164.974 64.6421 165.798 67.4307 166.76 68.1079C166.073 68.3602 165.888 68.0668 165.722 67.8575Z"
      fill="#FFE3E2"
      fill-opacity="0.19"
    />
    <circle
      opacity="0.2"
      cx="155.163"
      cy="66.8915"
      r="2.8632"
      transform="rotate(9.3177 155.163 66.8915)"
      fill="#FE8B87"
    />
    <path
      d="M83.6478 111.492C108.674 82.2351 135.389 82.7591 143.196 88.6961C147.403 91.8959 146.855 108.89 131.647 114.304C131.647 114.304 115.727 117.722 98.8346 124.047C79.5701 131.259 58.8659 156.002 58.8659 156.002C57.8182 161.831 57.7049 163.056 53.8948 169.495C52.9127 171.154 51.0796 170.478 51.2456 168.879L52.075 163.643L43.408 169.183C41.9727 170.171 40.7588 168.567 41.9735 167.414L47.0525 162.965L39.987 165.918C38.1102 166.573 37.8348 164.642 38.9388 164.094L45.7836 160.975L40.4299 160.736C38.8293 160.565 38.4433 159.24 40.5409 158.751L46.3365 157.944L41.7002 157.211C40.2653 156.82 41.6455 155.446 46.3921 155.573C47.7724 154.199 50.4222 152.058 54.286 151.52C54.286 151.52 70.6015 126.744 83.6478 111.492Z"
      fill="#F6B4A8"
    />
    <path
      d="M85.5846 106.464C85.5846 106.464 124.69 72.215 144.823 88.3423C149.121 91.7845 148.433 107.75 133.746 114.572C133.746 114.572 113.542 119.332 97.2731 127.218C90.5044 123.201 87.5258 117.471 85.5846 106.464Z"
      fill="#006047"
    />
    <path
      opacity="0.2"
      d="M172.726 66.4721C171.007 66.6164 169.727 68.082 169.866 69.7455C169.938 70.6007 170.701 71.4859 171.339 71.9845C171.981 69.8655 172.263 68.6498 172.726 66.4721Z"
      fill="#FE8B87"
    />
    <path
      d="M19.139 235.889C21.5579 235.188 22.7552 235.868 26.4755 235.087C26.6355 236.204 31.5326 240.013 31.7328 240.759C29.5577 240.366 28.3161 241.739 27.7396 243.061C27.576 243.436 27.409 251.031 26.7846 255.592C26.5418 257.365 21.9768 250.128 20.9875 247.928L17.7305 242.406C17.2132 240.529 16.7202 236.59 19.139 235.889Z"
      fill="#E89889"
    />
    <path
      d="M18.8612 235.569C21.2625 234.81 22.5319 235.895 26.2323 235.024C26.3993 235.767 23.9527 235.731 24.1658 237.523C24.4508 239.919 23.2777 244.335 27.3783 246.413C27.8005 249.793 27.6069 250.479 27.9727 252.448C30.4303 265.679 17.9816 251.837 17.2838 242.378C17.1448 240.495 16.4599 236.328 18.8612 235.569Z"
      fill="#DCDCDC"
    />
    <path
      d="M104.94 232.168C113.999 229.382 120.322 225.815 120.322 225.815C111.756 217.155 109.75 212.158 103.787 207.878C103.787 207.878 96.9628 211.677 81.9633 208.94C62.7344 205.432 27.4728 234.067 26.4158 235.113C25.3588 236.158 29.0616 240.466 29.0616 240.466C29.0616 240.466 62.6893 245.162 104.94 232.168Z"
      fill="#E89889"
    />
    <path
      d="M146.996 70.459C150.316 75.9015 151.321 78.2658 158.637 79.7564C158.637 79.7564 158.03 86.6903 159.674 88.318C161.318 89.9458 165.581 91.1889 165.79 91.3997C165.79 91.3997 164.364 101.132 161.565 99.4315C157.914 97.2123 156.371 97.2947 152.211 93.1391C148.052 88.9836 146.996 70.459 146.996 70.459Z"
      fill="#E89889"
    />
    <path
      d="M169.58 32.782C160.774 12.4741 134.989 25.3655 139.205 43.7325C139.205 43.7325 133.611 39.2621 129.64 41.6867C115.483 50.3319 130.713 69.0351 141.697 70.6828C145.416 71.2406 145.671 66.0715 144.427 67.0042C142.406 68.5196 139.365 59.9008 142.021 57.0692C144.364 54.5714 145.324 59.5992 147.724 58.9382C151.53 57.8902 151.448 53.718 154.754 51.4424C154.754 51.4424 178.02 60.1246 183.619 52.409C197.916 32.7044 169.58 32.782 169.58 32.782Z"
      fill="#3A3D3D"
    />
    <path
      d="M143.458 71.2191C147.175 70.6491 146.879 65.5697 145.635 66.5024C143.412 68.1695 121.086 57.8301 129.643 41.6871C115.485 50.3323 131.199 73.0992 143.458 71.2191Z"
      fill="#2C3434"
    />
    <path
      d="M101.927 200.333C97.1131 185.129 104.596 176.153 104.596 176.153C104.596 176.153 112.863 178.847 137.681 180.835L144.655 196.249C144.655 196.249 165.697 226.52 170.396 242.105C177.695 266.31 153.651 303.253 153.651 303.253C151.1 304.054 150.106 302.724 148.024 302.961C142.54 293.451 153.348 273.061 149.744 253.927C149.744 253.927 110.079 226.082 101.927 200.333Z"
      fill="#F6B4A8"
    />
    <path
      d="M176.659 146.563C157.471 133.882 148.514 121.62 152.37 112.159C154.448 107.06 163.059 86.8131 171.926 100.364C171.926 100.364 183.035 117.346 193.183 132.308C204.756 149.372 229.587 160.677 229.587 160.677C235.46 160.042 236.669 159.803 243.792 161.737C245.628 162.236 245.415 164.251 243.833 164.546L238.584 165.226L246.005 172.244C247.301 173.386 246.046 175.052 244.642 174.177L239.133 170.41L243.675 176.58C244.757 178.256 242.96 179.085 242.167 178.145L237.518 172.243L238.571 177.629C238.789 179.268 237.604 180.032 236.63 178.089L234.462 172.564L234.867 177.38C234.833 178.918 233.178 177.942 232.162 173.19C230.506 172.214 227.806 170.197 226.361 166.514C226.361 166.514 193.202 157.496 176.659 146.563Z"
      fill="#E89889"
    />
    <path
      d="M174.92 146.687C164.76 146.52 149.866 108.291 161.218 98.9544C163.764 96.8602 160.07 81.5757 173.1 94.8829C186.13 108.19 187.759 117.218 193.708 131.156C190.709 138.433 185.533 143.18 174.92 146.687Z"
      fill="#006047"
    />
    <path
      d="M157.574 183.537C157.574 183.537 136.373 182.572 121.85 167.645C113.303 158.861 112.927 179.308 106.808 210.177C119.191 218.167 129.832 226.521 141.282 224.852C153.356 209.276 152.966 201.507 157.574 183.537Z"
      fill="#DCDCDC"
    />
    <path
      d="M98.6025 197.062C93.7858 181.832 104.646 163.12 104.646 163.12C104.646 163.12 115.489 174.297 138.703 180.805C147.959 189.766 156.019 205.692 160.517 220.621C160.517 220.621 148.338 237.065 121.526 236.258C121.526 236.258 106.76 222.854 98.6025 197.062Z"
      fill="white"
    />
    <path
      d="M141.83 86.3955C140.778 85.7372 139.342 86.2694 137.9 87.7642C130.832 95.0952 123.92 106.121 123.92 106.121C123.92 106.121 125.155 128.479 118.682 138.711C112.208 148.942 104.282 163.056 104.282 163.056C111.855 178.317 142.247 181.957 157.862 183.604C161.345 171.456 162.567 144.815 169.986 137.77C182.935 126.867 175.957 115.956 172.622 105.658C169.054 94.6409 172.645 90.6915 162.533 90.7724C162.362 96.4113 145.645 88.7824 141.83 86.3955Z"
      fill="#006047"
    />
    <path
      d="M133.603 110.152C127.818 114.903 127.043 115.708 123.171 117.604C122.825 131.505 114.096 144.688 109.931 154.932C123.229 151.193 128.75 128.38 133.603 110.152Z"
      fill="#005640"
    />
    <path
      d="M146.294 307.833C146.202 305.316 147.152 304.32 147.298 300.521C150.384 300.84 153.149 300.697 153.529 300.85C153.908 301.002 165.623 304.288 169.895 306.001C171.557 306.668 171.413 307.712 169.039 308.137L152.275 310.782C150.328 310.828 146.387 310.349 146.294 307.833Z"
      fill="#F6B4A8"
    />
    <path
      d="M145.806 307.915C145.734 305.397 146.692 304.409 146.869 300.611C147.629 300.654 146.923 302.997 148.705 303.283C151.209 303.685 158.063 304.778 157.906 302.051C160.116 302.445 162.306 302.861 164.142 303.298C174.594 305.785 170.925 307.999 168.547 308.404L151.762 310.913C149.815 310.942 145.878 310.432 145.806 307.915Z"
      fill="white"
    />
    <path
      d="M153.849 310.627C153.849 310.627 161.814 308.05 167.761 306.949C167.965 306.912 171.064 305.979 171.183 306.617C171.458 308.104 168.577 309.322 153.849 310.627Z"
      fill="#DCDCDC"
    />
    <path
      d="M141.783 102.246L170 105.499C172.065 109.885 172.5 124 168.004 131.999L140.565 127.372C142.42 118.556 143.5 110.5 141.783 102.246Z"
      fill="white"
    />
    <path
      d="M158.272 111.635L160.322 111.848L158.963 123.092L156.621 122.849L157.652 114.32L155.771 114.677L155.466 112.418L158.272 111.635Z"
      fill="#00B183"
    />
    <path
      d="M145.107 121.903L145.332 120.04L149.245 116.477C150.033 115.768 150.459 115.14 150.525 114.594C150.568 114.24 150.505 113.946 150.335 113.712C150.167 113.467 149.913 113.326 149.571 113.291C148.888 113.22 148.336 113.575 147.916 114.355L146.093 112.881C146.502 112.111 147.045 111.549 147.72 111.196C148.395 110.843 149.103 110.705 149.845 110.782C150.801 110.881 151.576 111.293 152.171 112.016C152.776 112.729 153.013 113.632 152.881 114.724C152.737 115.913 152.063 117.036 150.858 118.092L148.933 119.795L152.373 120.152L152.074 122.626L145.107 121.903Z"
      fill="#00B183"
    />
    <path
      d="M166.133 112.043L168.182 112.256L166.824 123.499L164.482 123.256L165.512 114.727L163.631 115.085L163.326 112.825L166.133 112.043Z"
      fill="#00B183"
    />
  </svg>
);
