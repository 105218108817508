import { styled } from "../core/styledComponents";

export const Title = styled.div`
  font-family: GilroySemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 43px;
  line-height: 100%;
  color: #004836;
`;
