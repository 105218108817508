import React, { useRef, useState } from "react";
import { styled } from "../../core/styledComponents";

export const MouseScrollContainer: React.FC = ({ children }) => {
  const [down, setDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const slider = useRef<any>(null);

  const mouseDown = (e) => {
    setDown(true);
    setStartX(e.pageX - slider.current.offsetLeft);
    setScrollLeft(slider.current.scrollLeft);
  };
  const mouseAppear = () => {
    setDown(false);
  };

  const mouseMove = (e) => {
    if (!down) return;
    e.preventDefault();
    const x = e.pageX - slider.current.offsetLeft;
    const walk = x - startX;
    slider.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <Container
      ref={slider}
      onMouseMove={mouseMove}
      onMouseUp={mouseAppear}
      onMouseLeave={mouseAppear}
      onMouseDown={mouseDown}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{ active?: boolean }>`
  overflow-x: auto;
  margin: 0 10px;
  /* padding-bottom: 2px; */
  justify-content: center;
  cursor: grab;

  scrollbar-color: #00b183 #edfffa;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background-color: #edfffa;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #edfffa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00b183;
  }
`;
