import * as React from "react";

export const Calendar: React.FC = () => (
  <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.3809 2.07792V0H21.8874V2.07792H13.4373V0H10.9437V2.07792H0V32H35.3247V2.07792H24.3809ZM10.9437 4.57143V6.64935H13.4373V4.57143H21.8875V6.23377H24.381V4.57143H32.8312V8.72727H2.49351V4.57143H10.9437ZM2.49351 29.5065V11.2208H32.8312V29.5065H2.49351Z"
      fill="#004836"
    />
    <path d="M12.2594 22.0273H8.10352V26.1832H12.2594V22.0273Z" fill="#004836" />
    <path d="M19.7398 22.0273H15.584V26.1832H19.7398V22.0273Z" fill="#004836" />
    <path d="M27.2203 22.0273H23.0645V26.1832H27.2203V22.0273Z" fill="#004836" />
    <path d="M19.7398 14.5459H15.584V18.7017H19.7398V14.5459Z" fill="#004836" />
    <path d="M27.2203 14.5459H23.0645V18.7017H27.2203V14.5459Z" fill="#004836" />
    <path d="M12.2594 14.5459H8.10352V18.7017H12.2594V14.5459Z" fill="#004836" />
  </svg>
);
