import * as React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import map from "../shared/img/green_map.svg";

export const SectionLocation: React.FC = (props) => {
  return (
    <Container id={"place"}>
      <div className={"container"}>
        <TextContainer className={"col-lg-6"}>
          <img alt={"Map"} src={map} />
          <Title>Тирасполь, площадь Суворова</Title>
          <TimeBlock>
            <Time>9:00</Time>
            <Description>Регистрация</Description>
            <Time>9:40</Time>
            <Description>Зажигательная разминка!</Description>
            <Time>10:00</Time>
            <Description>Открытие и старт забега</Description>
            <Time>11:30</Time>
            <Description>Детский забег</Description>
            <Time>12:00</Time>
            <Description>Награждение всех участников</Description>
            <Time>12:30</Time>
            <Description>Развлекательная программа</Description>
          </TimeBlock>
        </TextContainer>
        <ImageContainer className={"col-lg-6"}>
          <Iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vVzdJU9aMSM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></Iframe>
        </ImageContainer>
      </div>
    </Container>
  );
};
const Container = styled.div`
  background: ${theme.colors.white};
  padding-top: 80px;
  padding-bottom: 100px;
  .container {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Iframe = styled.iframe`
  @media (max-width: 768px) {
    max-width: 1000px;
    width: 100%;
  }
`;

const TextContainer = styled.div``;

const Title = styled.div`
  font-family: GilroySemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  color: ${theme.colors.textGreen};

  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
  }
  @media (min-width: 992px) {
    margin-top: 80px;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const TimeBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  font-family: GilroyLight, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 220%;
  color: ${theme.colors.textGreen};
  margin-left: 30px;

  @media (max-width: 767px) {
    font-size: 16px;
    font-family: GilroyMedium, sans-serif;
  }
`;

const Time = styled.div`
  display: flex;
  align-items: start;
  font-family: GilroySemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 220%;
  color: ${theme.colors.green};

  min-width: 60px;
`;
