import * as React from "react";

export const MainRoad: React.FC = () => (
  <svg width="3141" height="2400" viewBox="0 0 3141 2400" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="2062.03"
      cy="201.31"
      rx="48.3854"
      ry="46.6305"
      transform="rotate(14.0634 2062.03 201.31)"
      fill="#088262"
    />
    <ellipse
      cx="2109.23"
      cy="211.451"
      rx="56.7396"
      ry="54.6817"
      transform="rotate(14.0634 2109.23 211.451)"
      fill="#088262"
    />
    <ellipse
      cx="2162.23"
      cy="253.04"
      rx="27.2495"
      ry="28.6793"
      transform="rotate(14.0634 2162.23 253.04)"
      fill="#088262"
    />
    <ellipse
      cx="2000.03"
      cy="183.759"
      rx="33.4414"
      ry="35.1961"
      transform="rotate(14.0634 2000.03 183.759)"
      fill="#088262"
    />
    <ellipse
      cx="1676.38"
      cy="76.1441"
      rx="64.6472"
      ry="62.3025"
      transform="rotate(14.0634 1676.38 76.1441)"
      fill="#088262"
    />
    <ellipse cx="1775" cy="113.301" rx="64.6472" ry="62.3025" transform="rotate(14.0634 1775 113.301)" fill="#088262" />
    <ellipse
      cx="1845.15"
      cy="137.958"
      rx="36.4078"
      ry="38.3181"
      transform="rotate(14.0634 1845.15 137.958)"
      fill="#088262"
    />
    <ellipse
      cx="1730.62"
      cy="90.4972"
      rx="43.7389"
      ry="43.54"
      transform="rotate(14.0634 1730.62 90.4972)"
      fill="#088262"
    />
    <ellipse
      cx="2453.37"
      cy="496.215"
      rx="48.3854"
      ry="46.6305"
      transform="rotate(44.0634 2453.37 496.215)"
      fill="#088262"
    />
    <ellipse
      cx="2500.78"
      cy="528.62"
      rx="48.3854"
      ry="46.6305"
      transform="rotate(44.0634 2500.78 528.62)"
      fill="#088262"
    />
    <ellipse
      cx="2514.28"
      cy="591.116"
      rx="27.2495"
      ry="28.6793"
      transform="rotate(44.0634 2514.28 591.116)"
      fill="#088262"
    />
    <ellipse
      cx="2400.56"
      cy="433.043"
      rx="27.2495"
      ry="28.6793"
      transform="rotate(44.0634 2400.56 433.043)"
      fill="#088262"
    />
    <ellipse
      cx="2415.63"
      cy="456.905"
      rx="27.2495"
      ry="28.6793"
      transform="rotate(44.0634 2415.63 456.905)"
      fill="#088262"
    />
    <ellipse
      cx="2640.01"
      cy="1586.9"
      rx="48.3854"
      ry="46.6305"
      transform="rotate(99.496 2640.01 1586.9)"
      fill="#088262"
    />
    <ellipse
      cx="2637.01"
      cy="1646.9"
      rx="48.3854"
      ry="46.6305"
      transform="rotate(99.496 2637.01 1646.9)"
      fill="#088262"
    />
    <ellipse
      cx="2597.82"
      cy="1692.09"
      rx="27.2495"
      ry="28.6793"
      transform="rotate(99.496 2597.82 1692.09)"
      fill="#088262"
    />
    <ellipse
      cx="2684.42"
      cy="1072.07"
      rx="64.6472"
      ry="62.3025"
      transform="rotate(-98.6057 2684.42 1072.07)"
      fill="#088262"
    />
    <ellipse
      cx="2704.31"
      cy="977.724"
      rx="64.6472"
      ry="62.3025"
      transform="rotate(-98.6057 2704.31 977.724)"
      fill="#088262"
    />
    <ellipse
      cx="2663.37"
      cy="904.214"
      rx="36.4078"
      ry="38.3181"
      transform="rotate(-98.6057 2663.37 904.214)"
      fill="#088262"
    />
    <ellipse
      cx="2197.2"
      cy="2118.98"
      rx="64.6472"
      ry="62.3025"
      transform="rotate(129.334 2197.2 2118.98)"
      fill="#088262"
    />
    <ellipse
      cx="2084.8"
      cy="2169.67"
      rx="52.1378"
      ry="50.2468"
      transform="rotate(129.334 2084.8 2169.67)"
      fill="#088262"
    />
    <ellipse
      cx="2149.26"
      cy="2170.06"
      rx="44.6577"
      ry="47.0009"
      transform="rotate(129.334 2149.26 2170.06)"
      fill="#088262"
    />
    <ellipse
      cx="2676.76"
      cy="1016.49"
      rx="43.7388"
      ry="43.54"
      transform="rotate(-98.6057 2676.76 1016.49)"
      fill="#088262"
    />
    <ellipse
      cx="1083.24"
      cy="2170.65"
      rx="58.7597"
      ry="56.6285"
      transform="rotate(-148.504 1083.24 2170.65)"
      fill="#088262"
    />
    <ellipse
      cx="1018.48"
      cy="2144.76"
      rx="58.7597"
      ry="56.6285"
      transform="rotate(-148.504 1018.48 2144.76)"
      fill="#088262"
    />
    <ellipse
      cx="985.963"
      cy="2074.26"
      rx="33.0921"
      ry="34.8284"
      transform="rotate(-148.504 985.963 2074.26)"
      fill="#088262"
    />
    <ellipse
      cx="1150.94"
      cy="2200.82"
      rx="44.516"
      ry="46.8517"
      transform="rotate(-148.504 1150.94 2200.82)"
      fill="#088262"
    />

    <ellipse
      cx="1690.51"
      cy="2296.18"
      rx="82.4111"
      ry="79.4221"
      transform="rotate(-162.329 1690.51 2296.18)"
      fill="#088262"
    />
    <ellipse
      cx="1579.54"
      cy="2305.21"
      rx="64.6472"
      ry="62.3025"
      transform="rotate(-162.329 1579.54 2305.21)"
      fill="#088262"
    />
    <ellipse
      cx="691.515"
      cy="1787.45"
      rx="66.2877"
      ry="63.8834"
      transform="rotate(-125.425 691.515 1787.45)"
      fill="#088262"
    />
    <ellipse
      cx="635.758"
      cy="1731.95"
      rx="66.2877"
      ry="63.8834"
      transform="rotate(-125.425 635.758 1731.95)"
      fill="#088262"
    />
    <ellipse
      cx="633.188"
      cy="1644.39"
      rx="37.3317"
      ry="39.2904"
      transform="rotate(-125.425 633.188 1644.39)"
      fill="#088262"
    />
    <ellipse
      cx="746.868"
      cy="1885.74"
      rx="37.3317"
      ry="39.2904"
      transform="rotate(-125.425 746.868 1885.74)"
      fill="#088262"
    />
    <ellipse
      cx="732.537"
      cy="1849.83"
      rx="37.3317"
      ry="39.2904"
      transform="rotate(-125.425 732.537 1849.83)"
      fill="#088262"
    />
    <ellipse
      cx="755.096"
      cy="512.712"
      rx="67.7712"
      ry="65.3132"
      transform="rotate(-56.7658 755.096 512.712)"
      fill="#088262"
    />
    <ellipse
      cx="792.77"
      cy="437.474"
      rx="67.7712"
      ry="65.3132"
      transform="rotate(-56.7658 792.77 437.474)"
      fill="#088262"
    />
    <ellipse
      cx="868.504"
      cy="401.628"
      rx="38.1672"
      ry="40.1698"
      transform="rotate(-56.7658 868.504 401.628)"
      fill="#088262"
    />
    <ellipse
      cx="543.786"
      cy="1007.95"
      rx="64.6473"
      ry="62.3025"
      transform="rotate(92.5693 543.786 1007.95)"
      fill="#088262"
    />
    <ellipse
      cx="505.987"
      cy="1096.66"
      rx="64.6473"
      ry="62.3025"
      transform="rotate(92.5693 505.987 1096.66)"
      fill="#088262"
    />
    <ellipse
      cx="540.609"
      cy="1169.15"
      rx="44.3472"
      ry="46.6741"
      transform="rotate(92.5693 540.609 1169.15)"
      fill="#088262"
    />
    <ellipse
      cx="540.529"
      cy="1063.96"
      rx="43.7389"
      ry="43.54"
      transform="rotate(92.5693 540.529 1063.96)"
      fill="#088262"
    />
    <ellipse
      cx="1289.55"
      cy="178.933"
      rx="64.6472"
      ry="62.3025"
      transform="rotate(-47.0576 1289.55 178.933)"
      fill="#088262"
    />
    <ellipse
      cx="1404.91"
      cy="135.424"
      rx="52.1378"
      ry="50.2468"
      transform="rotate(-47.0576 1404.91 135.424)"
      fill="#088262"
    />
    <ellipse
      cx="1340.61"
      cy="130.98"
      rx="44.6577"
      ry="47.0009"
      transform="rotate(-47.0576 1340.61 130.98)"
      fill="#088262"
    />
    <circle cx="1620.08" cy="1200.49" r="1076" fill="#BCDCE7" />
    <circle cx="1620.08" cy="1200.49" r="959.5" fill="#BCDCE7" stroke="white" strokeWidth="7" strokeDasharray="40 30" />
  </svg>
);
