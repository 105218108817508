import * as React from "react";
import { styled } from "../../core/styledComponents";

export const ElementInfo: React.FC<{ background; title; description; image; color }> = (props) => {
  return (
    <Container background={props.background} className={"col-lg-3 col-md-6"}>
      <img src={props.image} alt={""} />
      <Title color={props.color}>{props.title}</Title>
      <Description color={props.color}>{props.description}</Description>
    </Container>
  );
};

const Container = styled.div<{ background: string }>`
  background: ${(props) => props.background} !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 40px;

  @media (max-width: 1199px) and (min-width: 992px) {
    padding: 30px 30px;
  }
  @media (max-width: 767px) {
    margin-top: 3px;
  }
  @media (prefers-color-scheme: dark) {
    background: ${(props) => props.background} !important;
  }
`;

const Title = styled.div<{ color: string }>`
  font-family: GilroySemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  color: ${(props) => `${props.color} !important`};
  margin-top: 30px;

  @media (prefers-color-scheme: dark) {
    color: ${(props) => `${props.color} !important`};
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 18px;
  }
`;

const Description = styled.div<{ color: string }>`
  font-family: GilroyRegular, sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 160%;
  color: ${(props) => `${props.color} !important`};

  @media (prefers-color-scheme: dark) {
    color: ${(props) => `${props.color} !important`};
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 14px;
  }
`;
