import * as React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { Title } from "../ui/title";
import bg from "../shared/img/bg_man_women.png";
import { GreenButton } from "../ui/GreenButton";

export const SectionItRace: React.FC = () => {
  return (
    <Container>
      <div className={"container"}>
        <Content>
          <div className={"col-lg-7"}>
            <ThisTitle>IT-забег это не просто бег</ThisTitle>
            <Description>
              Помимо самого забега вас ожидают интеллектуальные игры, мастер-классы, зажигательные разминки и программы
              развлечения для детей и взрослых.
              <br />
              Мы вместе с вами подарим отличные эмоции и заряд здоровья всему городу!
            </Description>
          </div>
          <div className="href-scroll">
            <GreenButton className="second">Бесплатная регистрация</GreenButton>
          </div>
        </Content>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: ${theme.colors.white};
`;

const Content = styled.div`
  padding: 140px 60px;
  @media (max-width: 1199px) {
    padding: 115px 40px;
  }
  @media (max-width: 992px) {
    padding: 90px 40px;
  }

  background: url(${bg}) no-repeat;
  background-size: contain;
  position: relative;
  @media (max-width: 992px) {
    background: #eaf3fd;
  }
`;

const ThisTitle = styled(Title)`
  font-size: 40px;
  @media (max-width: 1199px) {
    font-size: 34px;
  }
`;

const Description = styled.div`
  font-family: GilroyRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #004836;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 17px;
  }
`;
