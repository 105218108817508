import React, {FC, useEffect, useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
import {theme} from "../core/theme";
import iconArrow from '../shared/icons/arrow-select.svg'


const options =["Детский забег", "2 км (фановый забег)", "5 км", "10 км", "21 км (полумарафон)"];


interface IProps {
    setDistance: (value: string) => void;
    error: boolean;
}

export const CustomSelect: FC<IProps> = ({setDistance, error}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const ref = useRef<any>(null);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
        setDistance(value)
    };

    const handleClick = (e: Event) => {
        if ( ref.current && !ref.current.contains(e.target)) {
            setIsOpen(false)
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <Wrapper ref={ref} error={error} onClick={toggling}>
            <div>
                {selectedOption || "Дистанция*"}
                <Img open={isOpen} src={iconArrow} alt="icon"/>
            </div>
            {isOpen && (
                    <Select open={isOpen}>
                        {options.map(option => (
                            <Option onClick={onOptionClicked(option)} key={Math.random()}>
                                {option}
                            </Option>
                        ))}
                    </Select>
            )}
        </Wrapper>
    );
}

const animate = keyframes`
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`


const Wrapper = styled.div<{error: boolean}>`
  position: relative;
  font-family: GilroyRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: ${({error}) => error ? theme.colors.error : theme.colors.white};
  width: 100%;
  cursor: pointer;

  background: inherit;
  border: none;
  padding: 8px;
  
`;

const Img = styled.img<{open: boolean}>`
  position: absolute;
  right: 0px;
  top: 50%;
  transition: all 0.3s ease-in-out;
  transform: translate(-50%, -50%) rotate(${({open}) => open ? '180deg' : 0});
`

const Select = styled.ul<{open: boolean}>`
  position: absolute;
  left: 0;
  z-index: 20;
  list-style-type: none;
  background: ${theme.colors.white};
  color: #3faffa;
  padding: 8px;
  margin-top: 10px;
  width: 100%;
  animation: ${animate} 0.3s ease-in-out;

  @media (max-width: 420px){
    width: 100%;
  }
`;
const Option = styled.li`
  color: ${theme.colors.gray};
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  
  &:hover {
    background: rgba(255, 31, 31, 0.08);
  }
`;

