import React from "react";
import { styled } from "../core/styledComponents";
import { LinkBlock } from "./heder/NavLink";
import { Burger } from "../shared/icons/Burger";
import { LogoDex } from "../shared/icons/LogoDex";
import { theme } from "../core/theme";
// import {LogoRunIt} from "../shared/icons/LogoRunIt";

interface IState {
  notTop: string;
  open: string;
}

export class Header extends React.Component<{}, IState> {
  public readonly state = { notTop: "", open: "" };

  public componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
  }

  public componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  public render() {
    return (
      <HeaderSection className={this.state.notTop}>
        <NavContainer className={"container"}>
          <LeftBlock>
            <a href={"https://dex-it.ru"}>
              <LogoDex color={this.state.notTop ? theme.colors.white : theme.colors.black} />
            </a>
            <LogoText className={this.state.notTop}>
              Мы ждем всех, кто бежит
              <br /> в ногу со временем!
            </LogoText>
          </LeftBlock>
          <MenuWrapper onClick={this.closeMenu} className={this.state.open} />
          <LinkBlock onClick={this.closeMenu} open={this.state.open} notTop={this.state.notTop} />
          <StyledBurger onClick={this.openMenu}>
            <Burger />
          </StyledBurger>
        </NavContainer>
      </HeaderSection>
    );
  }

  private handleScroll = () => {
    if (window.pageYOffset === 0 && !this.state.open) {
      this.setState({ notTop: "" });
    } else if (!this.state.notTop) {
      this.setState({ notTop: "notTop" });
    }
  };

  public openMenu = () => {
    if (!this.state.open) {
      this.setState({ notTop: "notTop", open: "open" });
    }
  };

  public closeMenu = () => {
    if (window.pageYOffset === 0) {
      this.setState({ notTop: "", open: "" });
    } else {
      this.setState({ open: "" });
    }
  };
}

const HeaderSection = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
  &.notTop {
    background: ${theme.colors.green};
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    padding: 15px 0;
  }
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    margin: 0;
  }
`;

const StyledBurger = styled.div`
  display: none;
  position: absolute;
  right: 15px;
  top: 10px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const MenuWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
  &.open {
    display: block;
  }
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 1199px) {
    a {
      margin-right: 20px;
    }
  }
`;

const LogoText = styled.div`
  font-family: GilroyLight, sans-serif;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: ${theme.colors.textGreen};
  margin-left: 20px;
  margin-right: 20px;
  &.notTop {
    color: ${theme.colors.white};
  }
`;
