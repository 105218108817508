import {reducerWithInitialState} from "typescript-fsa-reducers";
import {MailAction} from "./mailAction";

interface ILoading {
    request: string;
    name:string;
}

const MailState: ILoading = {
    request: "",
    name:"",
};

export const MailReducer = reducerWithInitialState(MailState)
    .case(MailAction.sendMail, (state, payload) => ({request: payload, name: state.name}))
    .case(MailAction.getName, (state, payload) => ({request: state.request, name:payload}));
