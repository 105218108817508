import * as React from "react";
import {styled} from "../../core/styledComponents";

export const LogoDex: React.FC<{color: string}> = (props) => (
    <Svg width="96" height="40" viewBox="0 0 76 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M50.5922 23.8084H34.6552C35.403 25.8996 37.1992 26.9451 40.0426 26.9451C41.868 26.9451 43.3038 26.3785 44.3523 25.2418L49.7397 28.3347C47.5538 31.4131 44.2913 32.951 39.9524 32.951C36.2114 32.951 33.1888 31.8301 30.8848 29.5894C28.6405 27.3488 27.5176 24.5258 27.5176 21.1192C27.5176 17.7734 28.6245 14.9651 30.8397 12.694C33.084 10.423 35.9567 9.28748 39.4591 9.28748C42.7203 9.28748 45.4298 10.423 47.584 12.694C49.7687 14.9055 50.8611 17.7126 50.8611 21.1192C50.8614 22.076 50.7711 22.972 50.5922 23.8084ZM34.5212 18.7885H44.1721C43.5144 16.4288 41.9278 15.2483 39.4142 15.2483C36.8106 15.2483 35.1788 16.4288 34.5212 18.7885Z"
            fill={`${props.color}`}/>
        <path d="M64.1035 15.8305H71.8236L76 9.91467H68.278L64.1035 15.8305Z" fill="#FC336F"/>
        <path
            d="M75.726 32.3235H68.0041L64.1009 26.7695L60.1978 32.3235H52.4759L60.2406 21.3009L60.2383 21.2978L52.2021 9.91467H59.9241L64.0986 15.8305L64.1009 15.8337L64.1032 15.8305L67.9635 21.2978L67.9612 21.3009L75.726 32.3235Z"
            fill={`${props.color}`}/>
        <path
            d="M23.7028 21.119C23.7028 21.1186 23.7026 21.118 23.7026 21.1175V0.950928H16.9683V10.9909C15.8749 9.89932 13.6925 9.2873 11.8514 9.2873C5.30604 9.2873 0 14.5845 0 21.119C0 27.6535 5.30604 32.9508 11.8514 32.9508C13.9601 32.9508 15.5761 32.4175 16.9683 31.2442V32.3234H23.7026V21.1205C23.7026 21.12 23.7028 21.1195 23.7028 21.119ZM11.8514 26.0987C9.09654 26.0987 6.86346 23.8692 6.86346 21.119C6.86346 18.3689 9.09654 16.1394 11.8514 16.1394C14.6061 16.1394 16.8393 18.3689 16.8393 21.119C16.8393 23.8692 14.6061 26.0987 11.8514 26.0987Z"
            fill={`${props.color}`}/>
    </Svg>
);

const Svg = styled.svg`
`;