import * as React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { Title } from "../ui/title";
import { VideoBlock } from "./videoBlock";

export const SectionWhatIsIt: React.FC = () => {
  return (
    <Container id={"what"}>
      <div className={"container"}>
        <ContainerText className={"col-lg-6"}>
          <Title>Что такое ИТ ЗАБЕГ?</Title>
          <GreenTitle>8 октября, 09:00</GreenTitle>
          <Description>
            Приглашаем вас на самое масштабное спортивное мероприятие этого года!
            <br />
            Более 2000 участников от 4х до 86 лет, дистанции от 300 метров до полумарафона!
            <br />
            Медали призы и подарки каждому участнику!
            <br />
            Мы ждем всех любителей здорового образа жизни!
            <br />
            Берите семью, коллег и друзей! Вместе устроим настоящий праздник здоровья для нашего города!
          </Description>
        </ContainerText>
        <ContainerImage className={"col-lg-6"}>
          <VideoBlock />
        </ContainerImage>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: ${theme.colors.white};
  padding-top: 100px;

  .container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
`;

const GreenTitle = styled(Title)`
  color: ${theme.colors.green};
  margin-top: 30px;
`;

const ContainerImage = styled.div`
  img {
    width: 100%;
  }

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const ContainerText = styled.div``;

const Description = styled.div`
  font-family: GilroyRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.8;
  color: ${theme.colors.textGreen};
  margin-top: 40px;

  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 16px;
  }
`;
