import React, {useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Header } from "./components/heder";
import { configureStore } from "./core/store/configureStore";
import { styled, ThemeProvider } from "./core/styledComponents";
import { Footer } from "./components/footer";
import { ConnectedRouter } from "connected-react-router";
import { theme } from "./core/theme";
import { Provider } from "react-redux";
import { SectionHello } from "./components/sectionHello";
import { SectionWhatIsIt } from "./components/sectionWhatIsIt";
import { SectionBlockInfo } from "./components/sectionBlockInfo";
import { SectionItRace } from "./components/sectionItRace";
import { SectionLocation } from "./components/sectionLocation";
import { SectionMosaic } from './components/sectionMosaic';

const { history, store } = configureStore();

function App() {
    useEffect(() => {
        const scroll = document.getElementById('reg')
        const anchors =  document.getElementsByClassName('href-scroll');

        const scrolls = (e) => {
            e.preventDefault()

            scroll && scroll.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }

        // @ts-ignore
        for (let anchor of anchors) {
            anchor.addEventListener('click', scrolls)
        }

        return (() => {
            // @ts-ignore
            for (let anchor of anchors) {
                anchor.removeEventListener('click', scrolls)
            }
        })

    })
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={theme}>
                    <>
                        <Header />
                        <Container>
                            <SectionHello />
                            <SectionWhatIsIt />
                            <SectionBlockInfo />
                            <SectionItRace />
                            <SectionLocation />
                            <SectionMosaic/>
                        </Container>
                        <Footer />
                    </>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    );
}

export default App;

const Container = styled.div`
  @media (min-width: 992px){
    z-index: 100;
    margin-bottom: 740px;
  }
`;
