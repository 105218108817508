import { IThunkAction } from "../core/store/mainReducer";
import { MailAction } from "./mailAction";


export class MailActionAsync {

    public static SendMail(name: string, surname: string, email: string, work: string): IThunkAction {

        return async (dispatch) => {

            var values = {
                name,
                surname,
                email,
                work,
            };

            var newValues = {
                name: values.name + " " + values.surname,
                message: "Отправлено с сайта run.dex. Телефон:" + values.work,
                phone: values.email
            };
            var body = new FormData();
            Object.keys(newValues).forEach(function (name) {
                body.append(name, newValues[name]);
            });

            // const headers = {Accept: "application/json", "Access-Control-Allow-Origin": "https://stage.day.dex-it.ru"};

            try { // https://dex-it.ru/Ru/Page
                dispatch(MailAction.sendMail("loading"));
                fetch("https://runsendmail.dex-it.ru/send", {
                    // headers,
                    method: "POST",
                    body: body
                }).then(function (m) {
                    if (m.status >= 200 && m.status < 300) {
                        dispatch(MailAction.sendMail("successful"));
                        dispatch(MailAction.getName(values.name));
                    } else {
                        dispatch(MailAction.sendMail("fail"));
                    }
                }).catch(function (m) {
                    dispatch(MailAction.sendMail("fail"));
                });
            } catch (e) {
                dispatch(MailAction.sendMail("fail"));
            }

        };
    }

}
