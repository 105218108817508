import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";

export const NavLink = styled.a<{ activeStyle?: boolean }>`
  font-family: GilroyMedium, sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 8px 3px 8px;
  color: ${theme.colors.textGreen};
  margin: 0 8px;
  &:hover {
    color: ${theme.colors.green};
    text-decoration: none;
    border: none;
  }
  &:active {
    text-decoration: none;
  }
  &.notTop {
    border-bottom: ${({ activeStyle }) => (activeStyle ? `1px solid ${theme.colors.white}` : "none")};
    color: ${theme.colors.white};
  }

  @media (max-width: 992px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    border-bottom: none;
    text-align: center;
    padding: 8px 0;
    font-size: 16px;
  }
`;
