import React, { SyntheticEvent } from "react";
import { styled } from "../../core/styledComponents";
import { theme } from "../../core/theme";
import { MailDispatch, MailState } from "./selectors";
import { CustomSelect } from "../../ui/CustomSelect";
import { connect } from "react-redux";
import { Spinner } from "../../ui/Spinner";

interface IProps extends ReturnType<typeof MailState>, ReturnType<typeof MailDispatch> {}

interface IState {
  name: string;
  surname: string;
  email: string;
  work: string;
  errorInput: boolean;
}

class FormInputConstructor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { name: "", email: "", surname: "", work: "", errorInput: false };
  }

  public render() {
    return (
      <>
        <Container>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.name.length === 0}
          >
            <Input
              name={"name"}
              type={"text"}
              placeholder={"Имя и Фамилия*"}
              onChange={this.changeName}
              error={this.state.errorInput && this.state.name.length === 0}
            />
          </InputContainer>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.surname.length === 0}
          >
            <CustomSelect
              setDistance={this.changeSurname}
              error={this.state.errorInput && this.state.surname.length === 0}
            />
          </InputContainer>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.email.length === 0}
          >
            <Input
              name={"e-mail"}
              type={"text"}
              placeholder={"Email*"}
              onChange={this.changeEmail}
              error={this.state.errorInput && this.state.email.length === 0}
            />
          </InputContainer>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.work.length === 0}
          >
            <Input
              name={"work"}
              type={"text"}
              placeholder={"Телефон*"}
              onChange={this.changeWork}
              error={this.state.errorInput && this.state.work.length === 0}
            />
          </InputContainer>
          <ButtonSubmit disabled={this.props.request === "loading"} onClick={this.Submit}>
            Отправить
            {this.props.request === "loading" && <Spinner />}
          </ButtonSubmit>
        </Container>
        {this.props.request === "fail" && (
          <ErrorMessage> При регистрации произошла ошибка. Повторите попытку позже</ErrorMessage>
        )}
      </>
    );
  }

  private changeName = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ name: event.currentTarget.value });
  };

  private changeSurname = (str: string) => {
    this.setState({ surname: str });
  };

  private changeEmail = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ email: event.currentTarget.value });
  };

  private changeWork = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ work: event.currentTarget.value });
  };
  private Submit = () => {
    const { name, surname, work, email } = this.state;
    if (name !== "" && surname !== "" && email !== "" && work !== "") {
      this.props.sendMail(name, surname, email, work);
    } else {
      this.setState({ errorInput: true });
    }
  };
}

export const FormInput = connect(MailState, MailDispatch)(FormInputConstructor);

const Container = styled.div`
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const InputContainer = styled.div<{ error?: boolean }>`
  padding-left: 0;
  padding-right: 30px;
  margin-top: 40px;
  @media (max-width: 767px) {
    padding-right: 0;
  }

  &:before {
    content: " ";
    width: calc(100% - 30px);
    height: 1px;
    background: ${(props) => (props.error ? theme.colors.error : theme.colors.white)};
    position: absolute;
    bottom: 0;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

const Input = styled.input<{ error?: boolean }>`
  font-family: GilroyRegular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: ${theme.colors.white};
  width: 100%;

  background: inherit;
  border: none;
  padding: 8px 30px 8px 8px;
  outline: none !important;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => (props.error ? theme.colors.error : theme.colors.white)};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => (props.error ? theme.colors.error : theme.colors.white)};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => (props.error ? theme.colors.error : theme.colors.white)};
  }
`;

const ButtonSubmit = styled.button`
  position: relative;
  user-select: none;
  background: ${theme.colors.white};
  color: ${theme.colors.green};
  padding: 10px 50px;
  font-family: GilroyMedium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 50px;
  margin-right: 30px;
  transition: all 0.1s linear;

  &:focus,
  &:focus-visible {
    outline: none !important;
  }

  :active {
    text-decoration: none;
    color: ${theme.colors.white};
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #00b183;
  }

  :hover {
    text-decoration: none;
    background: #e6f8f3;
  }

  &:disabled {
    cursor: default;
    background: #5e87c1;
    color: #cbcbcb;
    opacity: 0.8;
  }

  @media (max-width: 767px) {
    margin-right: 0;
  }

  @media (max-width: 420px) {
    font-size: 20px;
    padding: 10px 40px;
    width: 100%;
    text-align: center;
  }
`;

const ErrorMessage = styled.div`
  font-size: 18px;
  font-family: GilroyMedium, sans-serif;
  color: ${theme.colors.error};
  margin-top: 16px;
`;
