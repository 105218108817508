export interface ITheme {
  boxShadow: string;
  fontFamily: string;
  maxWidth: number;
  mobileWidth: number;
  mainContentWidth: number;
  columnMaxWidth: number;
  colors: {
    black: string;
    white: string;
    red: string;
    blue: string;
    gray: string;
    error: string;
    green: string;
    whiteGreen: string;
    textGreen: string;
  };
  sizes: {
    mainMenuFoldingWidth: number;
  };
}

export const theme: ITheme = {
  boxShadow: "0 2px 6px 1px rgba(0, 0, 0, 0.03)",
  colors: {
    white: "#ffffff",
    black: "#000000",
    red: "#DC1717",
    blue: "#3E81E0",
    gray: "#1D3456",
    error: "orange",
    green: "#00B183",
    whiteGreen: "#E0EEFF",
    textGreen: "#004836",
  },
  fontFamily: "GilroyRegular",
  sizes: {
    mainMenuFoldingWidth: 1000,
  },
  maxWidth: 1440,
  mobileWidth: 420,
  mainContentWidth: 1182,
  columnMaxWidth: 360,
};
